import { LISTAR_INVITACIONES, CREAR_INVITACION, EDITAR_INVITACION, ENVIAR_INVITACION, ELIMINAR_INVITACION, DETALLE_INVITACION } from '../actions/types'

const initialState = [];

const fn = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LISTAR_INVITACIONES:
            return {
                ...state,
                listado: payload
            }
        case CREAR_INVITACION:
            return {
                ...state,
                listado: [
                    ...state.listado, payload
                ]
            }
        case EDITAR_INVITACION:
            return {
                ...state,
                listado: state.listado?.map( invitacion => invitacion._id === payload._id ? payload : invitacion)
            }
        case ENVIAR_INVITACION:
            return {
                ...state,
                listado: state.listado?.map( invitacion => invitacion._id === payload._id ? payload : invitacion)
            }
        case ELIMINAR_INVITACION:
            return {
                ...state,
                listado: state.listado?.filter( invitacion => invitacion._id !== payload.id )
            }
        case DETALLE_INVITACION:
            return {
                ...state,
                detalle: payload
            }
        default:
            return state;
    }
}

export default fn;