import axios from 'axios';
import { LISTAR_INVITACIONES, CREAR_INVITACION, EDITAR_INVITACION, CONFIRMAR_INVITACION, ENVIAR_INVITACION, ELIMINAR_INVITACION, DETALLE_INVITACION } from './types';
import { setAlert } from './alert';
import { changeLoader } from './loader';

// LISTADO INVITACIONES
export const listarInvitaciones = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/listado`, body, config);
        dispatch(changeLoader(false));

        dispatch({
            type: LISTAR_INVITACIONES,
            payload: res.data.invitaciones
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// CREAR INVITACION
export const crearInvitacion = (invitacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(invitacion);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/crear`, body, config);

        dispatch({
            type: CREAR_INVITACION,
            payload: res.data.invitacion
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// EDITAR INVITACION
export const editarInvitacion = (invitacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(invitacion);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/editar`, body, config);

        dispatch({
            type: EDITAR_INVITACION,
            payload: res.data.invitacion
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// ENVIAR INVITACION
export const enviarInvitacion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id': id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/enviar`, body, config);

        dispatch({
            type: ENVIAR_INVITACION,
            payload: res.data.invitacion
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// ELIMINAR INVITACION
export const eliminarInvitacion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id': id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/eliminar`, body, config);

        dispatch({
            type: ELIMINAR_INVITACION,
            payload: {
                id
            }
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// DETALLE INVITACION
export const detalleInvitacion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id': id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/detalle`, body, config);

        dispatch({
            type: DETALLE_INVITACION,
            payload: res.data.invitacion
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}


// BOLETOS INVITACION
export const confirmarInvitacion = (invitacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(invitacion);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitaciones/confirmar`, body, config);
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}