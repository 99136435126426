import { BUSCAR_NOTAS, DETALLE_NOTA, ULTIMAS_NOTAS, LISTAR_NOTAS, CREAR_NOTA, EDITAR_NOTA, ELIMINAR_NOTA } from '../actions/types'

const initialState = [];

const fn = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case BUSCAR_NOTAS:
            return {
                ...state,
                notas: payload
            }
        case DETALLE_NOTA:
            return {
                ...state,
                detalle: payload
            }
        case ULTIMAS_NOTAS:
            return {
                ...state,
                ultimas: payload
            }
        case LISTAR_NOTAS:
            return {
                ...state,
                listado: payload
            }
        case CREAR_NOTA:
            return {
                ...state,
                listado: [
                    payload, ...state.listado
                ]
            }
        case EDITAR_NOTA:
            return {
                ...state,
                listado: state.listado?.map( nota => nota._id === payload._id ? payload : nota)
            }
        case ELIMINAR_NOTA:
            return {
                ...state,
                listado: state.listado?.filter( nota => nota._id !== payload.id )
            }
        default:
            return state;
    }
}

export default fn;