import axios from 'axios';
import { LISTAR_MESAS, CREAR_MESA, EDITAR_MESA, DETALLE_MESA, ELIMINAR_MESA } from '../actions/types';
import { setAlert } from './alert';
import { changeLoader } from './loader';

// LISTADO MESAS
export const listarMesas = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/mesas/listado`, body, config);
        dispatch(changeLoader(false));

        dispatch({
            type: LISTAR_MESAS,
            payload: res.data.mesas
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// CREAR MESA
export const crearMesa = (mesa) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(mesa);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/mesas/crear`, body, config);

        dispatch({
            type: CREAR_MESA,
            payload: res.data.mesa
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// EDITAR MESA
export const editarMesa = (mesa) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    mesa.id_compra = process.env.REACT_APP_ID_COMPRA;

    const body = JSON.stringify(mesa);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/mesas/editar`, body, config);

        dispatch({
            type: EDITAR_MESA,
            payload: res.data.mesa
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// DETALLE MESA
export const detallesMesa = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id': id, 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/mesas/detalle`, body, config);

        dispatch({
            type: DETALLE_MESA,
            payload: res.data.mesa
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// ELIMINAR MESA
export const eliminarMesa = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id': id });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/mesas/eliminar`, body, config);

        dispatch({
            type: ELIMINAR_MESA,
            payload: id
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}