import { LISTAR_INVITADOS, CONFIRMAR_INVITADO, EDITAR_INVITADO, ELIMINAR_INVITADO } from '../actions/types'

const initialState = {
    listado: []
};

const fn = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LISTAR_INVITADOS:
            return {
                ...state,
                listado: payload
            }
        case CONFIRMAR_INVITADO:
            return {
                ...state,
                listado: state.listado?.map( invitado => invitado._id === payload._id ? payload : invitado)
            }
        case EDITAR_INVITADO:
            return {
                ...state,
                listado: state.listado?.map( invitado => invitado._id === payload._id ? payload : invitado)
            }
        case ELIMINAR_INVITADO:
            return {
                ...state,
                listado: state.listado?.filter( invitado => invitado._id !== payload )
            }
        default:
            return state;
    }
}

export default fn;