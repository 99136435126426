import { LISTAR_MESAS, CREAR_MESA, EDITAR_MESA, DETALLE_MESA, ELIMINAR_MESA } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null
};

const fn = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case LISTAR_MESAS:
            return {
                ...state,
                listado: payload
            }
        case CREAR_MESA:
            return {
                ...state,
                listado: [
                    ...state.listado, payload
                ]
            }
        case EDITAR_MESA:
            return {
                ...state,
                listado: state.listado?.map( mesa => mesa._id === payload._id ? payload : mesa)
            }
        case DETALLE_MESA:
            return {
                ...state,
                detalles: payload
            }
        case ELIMINAR_MESA:
            return {
                ...state,
                listado: state.listado?.filter( mesa => mesa._id !== payload )
            }
        default:
            return state;
    }
}

export default fn;