import axios from 'axios';
import { OBTENER_CODIGO } from './types';
import { setAlert } from './alert';
import { changeLoader } from './loader';

// OBTENER CODIGO QR
export const obtenerCodigo = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        dispatch(changeLoader(true));
        let res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/invitaciones/codigo`, config);
        dispatch(changeLoader(false));

        dispatch({
            type: OBTENER_CODIGO,
            payload: res.data.codigo
        });

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// RECONECTAR A WHATSAPP
export const reconectarWhatsapp = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        dispatch(changeLoader(true));
        let res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/invitaciones/reconectar`, config);
        dispatch(changeLoader(false));

        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}