import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { listarInvitados, confirmarInvitado, editarInvitado, eliminarInvitado } from '../../Redux/actions/invitados';
import { listarMesas } from '../../Redux/actions/mesas';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    contenido_tabla: {
    	fontSize: '16px'
    },
    btn_tabla: {
    	fontFamily: 'inherit',
    	letterSpacing: 'inherit',
    	fontSize: '16px'
    },
    imagen_fila: {
    	maxHeight: '90px'
    },
    badge_boton: {
        cursor: 'pointer'
    }
}

const InvitadosListado = () => {

	const dispatch = useDispatch();

	const { listado } = useSelector(state => state.invitados);
	const mesas = useSelector(state => state.mesas);
    
    const [filas, setFilas] = useState([]);
    const [filterText, setFilterText] = useState('');
	const [edicionModal, setEdicionModal] = useState(false);
	const [eliminacionModal, setEliminacionModal] = useState(false);

    const [editarData, setEditarData] = useState({
        id: null,
        id_invitacion: null,
        id_mesa: null,
        tipo: null,
        nombre: null,
        status: null
    });

	const [eliminarData, setEliminarData] = useState({
        id: null,
        id_invitacion: null,
        tipo: null
    });

    const [tipoEditarSelectData, setTipoEditarSelectData] = useState(null);
    const [mesaEditarSelectData, setMesaEditarSelectData] = useState(null);

    const [tiposFilter, setTiposFilter] = useState([
        { value: 'Adulto', label: 'Adulto' },
        { value: 'Niño', label: 'Niño' }
    ]);

    const [mesasFilter, setMesasFilter] = useState([]);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Tipo', 
            selector: row => row.tipo,
        },
        {
            name: 'Nombre', 
            selector: row => row.nombre,
            wrap: true,
            grow: 3
        },
        {
            name: 'Mesa', 
            selector: row => row.mesa,
        },
        {
            name: 'Invitación', 
            selector: row => row.codigo,
        },
        {
            name: 'Status',
            cell: row => {
                return(
                    <span className={ 'badge badge-' + (row.status === true ? 'success' : 'danger')} style={styles.badge_boton} onClick={() => handleConfirmar(row)}>{row.status === true ? 'Asistirá' : 'No asistirá'}</span>
                )
            }
        }, 
        {
            name: '',
            grow: 1.5,
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link text-primary px-2 float-end" onClick={() => handleAbrirEditar(row)}><i className="icon-pencil"></i></button>
                        <button type="button" className="btn btn-sm btn-link text-danger px-2 float-end" onClick={() => handleAbrirEliminar(row)}><i className="icon-trash"></i></button>
                    </div>
                )
            }
        }
    ];

    //Filtrar
    const onChangeFilter = (e) => {
        setFilterText(e.target.value);
    }

    const handleClearFilter = () => {
        setFilterText('');
        setFilas(listado);
    }

    //Confirmar
    const handleConfirmar = item => {
        dispatch(confirmarInvitado(item._id, item.id_invitacion, item.tipo, item.status));
    }

    //Editar
    const handleAbrirEditar = async (item) => {
        setEdicionModal(true);
        setEditarData({
            ...editarData,
            id: item._id,
            id_invitacion: item.id_invitacion,
            id_mesa: item.id_mesa,
            tipo: item.tipo,
            nombre: item.nombre,
            status: item.status
        });

        let datos_tipo_select = null;
        let datos_mesa_select = null;

        if(item.tipo != null){

            let tipo_elegido = await tiposFilter?.filter(tipo => tipo.label == item.tipo);

            if(tipo_elegido.length > 0){
                datos_tipo_select = {
                    value: tipo_elegido[0].value,
                    label: tipo_elegido[0].label
                };
            }
        }

        if(item.id_mesa != null){

            let mesa_elegido = await mesas.listado?.filter(mesa => mesa._id == item.id_mesa);

            if(mesa_elegido.length > 0){

                let restantes = parseInt(mesa_elegido[0].disponibles);

                let palabra_restantes = 'restante';
                if(restantes != 1){
                    palabra_restantes = 'restantes';
                }

                datos_mesa_select = {
                    value: mesa_elegido[0]._id,
                    label: mesa_elegido[0].nombre + ' (' + restantes + ' ' + palabra_restantes +')'
                };
            }
        }

        setTipoEditarSelectData(datos_tipo_select);

        setMesaEditarSelectData(datos_mesa_select);
    }

    const onChangeEditar = (e) => {
        setEditarData({
            ...editarData,
            [e.target.name]: e.target.value
        });
    }

    const onChangeSelectSearch = async (option, origin) => {
        if(option != null){
            let {value, label} = option;

            switch(origin){

                case 'tipo':
                    await setTipoEditarSelectData({value, label});
                    break;

                case 'id_mesa':
                    await setMesaEditarSelectData(option);
                    break;

                default:
                    break;
            }

            await setEditarData({
                ...editarData,
                [origin]: value
            });          
        }else{
            switch(origin){

                case 'tipo':
                    await setTipoEditarSelectData(null);
                    break;

                case 'id_mesa':
                    await setMesaEditarSelectData(null);
                    break;

                default:
                    break;
            }

            await setEditarData({
                ...editarData,
                [origin]: null
            });
        }
    };

    const handleEditar = async () => {
        await dispatch(editarInvitado(editarData));
        await dispatch(listarMesas());
        await handleCerrarEditar();
    }

    const handleCerrarEditar = () => {
        setEdicionModal(false);
        setEditarData({
            ...editarData,
            id: null,
            id_invitacion: null,
            id_mesa: null,
            tipo: null,
            nombre: null,
            status: null
        });
    }

    //Eliminar
    const handleAbrirEliminar = item => {
        setEliminacionModal(true);
        setEliminarData({
            ...eliminarData,
            id: item._id,
            id_invitacion: item.id_invitacion,
            tipo: item.tipo
        });
    }

    const handleEliminar = async () => {
        await dispatch(eliminarInvitado(eliminarData));
        await dispatch(listarMesas());
        await handleCerrarEliminar();
    }

    const handleCerrarEliminar = () => {
        setEliminacionModal(false);
        setEliminarData({
            ...eliminarData, 
            id: null,
            id_invitacion: null,
            tipo: null
        });
    }

	useEffect(() => {
        dispatch(listarInvitados());
        dispatch(listarMesas());
    }, []);

    useEffect(() => {
        let opciones_mesas = [];

        for (var i = 0; i < mesas.listado?.length; i++) {
            let restantes = parseInt(mesas.listado[i]?.disponibles);

            let palabra_restantes = 'restante';
            if(restantes != 1){
                palabra_restantes = 'restantes';
            }

            let opcion_mesa = {
                value: mesas.listado[i]?._id,
                label: mesas.listado[i]?.nombre + ' (' + restantes + ' ' + palabra_restantes +')'
            };

            if(restantes == 0){
                opcion_mesa.isDisabled = true;
            }

            opciones_mesas.push(opcion_mesa);
        }

        setMesasFilter(opciones_mesas);
    }, [mesas]);

    useEffect(() => {
        setFilas(listado);
    }, [listado]);

    useEffect(() => {
        if(listado){
            setFilas(listado.filter(invitado => 
                (invitado.tipo && invitado.tipo.toLowerCase().includes(filterText.toLowerCase())) || 
                (invitado.nombre && invitado.nombre.toLowerCase().includes(filterText.toLowerCase())) || 
                (invitado.mesa && invitado.mesa.toLowerCase().includes(filterText.toLowerCase())) || 
                (invitado.codigo && invitado.codigo.toLowerCase().includes(filterText.toLowerCase())) || 
                (
                    (filterText.toLowerCase() == "asistirá" || filterText.toLowerCase() == "asistira") && invitado.status === true ||
                    (filterText.toLowerCase() == "no asistirá" || filterText.toLowerCase() == "no asistira") && !invitado.status
                )
            ));
        }
    }, [filterText]);
    
    return (
    	<Fragment>
            {/* Modal de edición */}
            <Modal show={edicionModal} size="md" onHide={() => { handleCerrarEditar() }}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar boleto de invitación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label className="font-weight-bold">Nombre</label>
                            <input type="text" className="form-control" placeholder="Nombre del invitado" name="nombre" onChange={e => onChangeEditar(e) } value={editarData.nombre}></input>
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Tipo</label>
                            <Select
                                name="tipo"
                                className="select-group"
                                options={tiposFilter}
                                value={tipoEditarSelectData}
                                onChange={(value) => onChangeSelectSearch(value, 'tipo')}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>

                        <div className="col-md-6 form-group">
                            <label className="font-weight-bold">Mesa</label>
                            <Select
                                name="id_mesa"
                                className="select-group"
                                isClearable={true}
                                options={mesasFilter}
                                value={mesaEditarSelectData}
                                onChange={(value) => onChangeSelectSearch(value, 'id_mesa')}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCerrarEditar}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleEditar}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

    		{/* Modal de eliminación */}
            <Modal show={eliminacionModal} onHide={() => { handleCerrarEliminar() }}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar boleto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Deseas eliminar esta boleto de invitación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleEliminar}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

			<div className="container">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center heading-section mt-3">
						<h2 style={styles.titulo_seccion}>Administra la ubicación y asistencia de cada invitado</h2>
					</div>
				</div>
			</div>

            <div className="container_admin">
				{
                    listado?.length > 0 ? 
                        <Fragment>
                            <div className="col-md-12 form-group text-left">
                                <label className="font-weight-bold">Búsqueda general</label>
                                <div class="input-group">
                                    <input type="text" className="form-control" placeholder="Buscar por tipo, nombre, mesa, invitación o status" value={filterText} onChange={onChangeFilter}></input>
                                    <div class="input-group-append">
                                        <button class="btn btn-danger" type="button" onClick={handleClearFilter}><i className="icon-cancel-circle"></i></button>
                                    </div>
                                </div>
                            </div>

                            <DataTable 
                                columns={columns}
                                data={filas}
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                            />
                        </Fragment>
                    :
                        <div className="text-center">Aún no has agregado invitados a tus 40 años 😱</div>
                }
			</div>
	    </Fragment>
    )
}

export default InvitadosListado;