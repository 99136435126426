import React, { useState, createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useHistory, useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';

import { editarBlog, detalleNota } from '../../Redux/actions/notas_blog';

registerLocale('es', es);

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    contenido_tabla: {
        fontSize: '16px'
    },
    btn_tabla: {
        fontFamily: 'inherit',
        letterSpacing: 'inherit',
        fontSize: '16px'
    },
    imagen_fila: {
        maxHeight: '90px'
    }
}

const BlogEditar = () =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const history = useHistory();

    const post = useSelector(state => state.notas_blog);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        try {

            formData.append('id', id);
            formData.append('id_compra', process.env.REACT_APP_ID_COMPRA);
            formData.append('titulo', blogData.titulo);
            formData.append('fecha', blogData.fecha);
            formData.append('imagen', blogData.imagen);
            formData.append('contenido', blogData.contenido);

            dispatch(editarBlog(formData));
            history.push('/blog_listado');
        } catch (error) {
            return false;
        }
    }
    
    const [blogData, setBlogData] = useState({
        id: '',
        titulo: '',
        fecha: '',
        imagen: undefined,
        contenido: ''
    });

    let imagen = createRef();

    const onChangeImagen = () => {
        setBlogData({
            ...blogData, imagen: imagen.current.files[0]
        })
    }
    
    const onChange = (e) => {
        setBlogData({
            ...blogData, [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        dispatch(detalleNota(id));
    }, [id, dispatch]);

    useEffect(() => {
        setBlogData({
            ...blogData,
            id: post.detalle?._id,
            titulo: post.detalle?.titulo,
            fecha: post.detalle?.fecha,
            contenido: post.detalle?.contenido
        })
    }, [post]);

    return(
        <div className="row">
            <form className="col-md-8 offset-md-2">
                <h2 className="mb-3 mt-5" style={styles.titulo_seccion}>Ediar post</h2>
                <div className="row">
                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Título</label> <br></br>
                        <input type="text" className="form-control" placeholder="Título del post" name="titulo" onChange={e => onChange(e) } value={blogData.titulo || ''}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Fecha</label> <br></br>
                        <div className="contenedor_datepicker">
                            <DatePicker className="form-control text-center" dateFormat="dd-MM-yyyy" locale="es" selected={ blogData.fecha ? new Date(blogData.fecha) : '' } onChange={ date => setBlogData({ ...blogData, fecha: date })}/>
                        </div>
                    </div>

                    <div className="col-md-4 form-group custom-file-upload">
                        <label className="font-weight-bold">Imagen</label> <br></br>
                        <label className="custom-file-upload1" htmlFor="imagen"> Elegir la imagen</label>
                        <input className="col-md-12" name="imagen" id="imagen" type="file" ref={imagen} onChange={onChangeImagen} accept="image/png, image/jpeg" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 form-group">
                        <label className="font-weight-bold">Contenido</label> <br></br>
                        <textarea className="form-control" rows="5" placeholder="Contenido del post" name="contenido" onChange={e => onChange(e) } value={blogData.contenido || ''}></textarea>
                    </div>
                </div>
                <button type="submit" className="btn btn-success float-right" onClick={handleSubmit} style={styles.btn_tabla}>Editar post</button>
            </form>
        </div>
    )
}

export default BlogEditar;