import axios from 'axios';
import { LISTAR_INVITADOS, CONFIRMAR_INVITADO, EDITAR_INVITADO, ELIMINAR_INVITADO } from './types';
import { setAlert } from './alert';
import { changeLoader } from './loader';

// LISTADO INVITADOS
export const listarInvitados = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitados/listado`, body, config);
        dispatch(changeLoader(false));

        dispatch({
            type: LISTAR_INVITADOS,
            payload: res.data.invitados
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// CONFIRMAR INVITADO
export const confirmarInvitado = (id, id_invitacion, tipo, status) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({
        'id': id,
        'id_invitacion': id_invitacion,
        'tipo': tipo,
        'status': status
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitados/confirmar`, body, config);

        dispatch({
            type: CONFIRMAR_INVITADO,
            payload: res.data.invitado
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// EDITAR INVITADO
export const editarInvitado = (invitado) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(invitado);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitados/editar`, body, config);

        dispatch({
            type: EDITAR_INVITADO,
            payload: res.data.invitado
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}

// ELIMINAR INVITADO
export const eliminarInvitado = (invitado) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(invitado);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/invitados/eliminar`, body, config);

        dispatch({
            type: ELIMINAR_INVITADO,
            payload: invitado.id
        });
        
        dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        console.log(error);
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}