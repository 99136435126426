import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { editarMesa, detallesMesa } from '../../Redux/actions/mesas';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    btn_tabla: {
        fontFamily: 'inherit',
        letterSpacing: 'inherit',
        fontSize: '16px'
    },
    check_asistencia: {
        display: 'block'
    }
}

const MesasEditar = () =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const history = useHistory();

    const { detalles } = useSelector(state => state.mesas);
    
    const [mesaData, setMesaData] = useState({
        id: null, 
        nombre: '',
        limite: 0
    });
    
    const onChange = (e) => {
        setMesaData({
            ...mesaData,
            [e.target.name]: e.target.value
        });
    }

    //Guardar
    const handleCancel = async (e) => {
        history.push('/mesas_listado');
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(editarMesa(mesaData));
            await history.push('/mesas_listado');
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        dispatch(detallesMesa(id));
    }, [dispatch, id]);

    useEffect(() => {
        setMesaData({
            ...mesaData,
            id: detalles?._id,
            nombre: detalles?.nombre,
            limite: detalles?.limite
        })
    }, [detalles]);

    return(
        <div className="row px-3">
            <form className="col-md-8 offset-md-2">
                <div className="row mb-4 mt-5">
                    <div className="col-md-8">
                        <h2 style={styles.titulo_seccion}>Editar mesa</h2>
                    </div>
                    <div className="col-md-4 text-right">
                        <button type="submit" className="btn btn-danger mr-2" onClick={() => handleCancel()} style={styles.btn_tabla}>Cancelar</button>
                        <button type="submit" className="btn btn-success float-right" onClick={handleSubmit} style={styles.btn_tabla}>Editar mesa</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 form-group">
                        <label className="font-weight-bold">Nombre o número de mesa</label>
                        <input type="text" className="form-control" placeholder="Nombre o número de la mesa" name="nombre" onChange={e => onChange(e)} value={mesaData.nombre}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Límite de invitados</label>
                        <input type="number" className="form-control" name="limite" onChange={e => onChange(e)} value={mesaData.limite}></input>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default MesasEditar;