import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { setAlert } from '../../Redux/actions/alert';
import { editarInvitacion, detalleInvitacion } from '../../Redux/actions/invitaciones';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    btn_tabla: {
        fontFamily: 'inherit',
        letterSpacing: 'inherit',
        fontSize: '16px'
    },
    check_asistencia: {
        display: 'block'
    }
}

const InvitacionesEditar = () =>{

    const { id } = useParams();

    const dispatch = useDispatch();

    const history = useHistory();

    const invitacion = useSelector(state => state.invitaciones);
    
    const [invitacionData, setInvitacionData] = useState({
        id: null, 
        referencia: '',
        numero: '',
        codigo: '',
        adultos: [],
        ninos: []
    });
    
    const onChange = (e) => {
        setInvitacionData({
            ...invitacionData,
            [e.target.name]: e.target.value
        });
    }

    //Adultos
    const onChangeAdultos = (e) => {
        let adultos = invitacionData.adultos;

        if(adultos?.length > e.target.value){
            adultos.splice(e.target.value, (adultos?.length - e.target.value));
        }else{
            for (var x = 1; x <= (e.target.value - adultos?.length); x++) {
                adultos.push({
                    nombre: '',
                    status: false
                });
            }
        }

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const onQuantityAdultos = (tipo) => {
        let adultos = invitacionData.adultos;

        if(tipo == 1){
            adultos.push({
                nombre: '',
                status: false
            });
        }else{
            adultos.splice(adultos.length - 1, 1);
        }

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const onChangeInvitadoAdulto = (e) => {
        let adultos = invitacionData.adultos;

        let campo_separado = e.target.name.split('_');
        let posicion = campo_separado[2];

        adultos[posicion].nombre = e.target.value;

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const onConfirmAdulto = async ({ currentTarget }) => {
        let adultos = invitacionData.adultos;

        let campo_separado = currentTarget.id.split('_');
        let posicion = campo_separado[2];

        adultos[posicion].status = !adultos[posicion].status;

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const onDeleteAdulto = async ({ currentTarget }) => {
        let adultos = invitacionData.adultos;

        let campo_separado = currentTarget.id.split('_');
        let posicion = campo_separado[2];

        console.log(adultos);
        
        adultos.splice(posicion, 1);
        
        console.log(adultos);

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const adultosRows = (adultos) => {

        let filas = [];

        for (var x = 0; x < adultos?.length; x++) {
            filas.push(
                <div className="form-group" key={'boleto_adulto_' + x}>
                    <label className="font-weight-bold">Adulto {x + 1}</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class={'btn btn_confirmar btn-' + (adultos[x].status == false ? 'light' : 'success')} type="button" id={'confirmacion_adulto_' + x} onClick={e => {onConfirmAdulto(e);}}><i className={'icon-' + (adultos[x].status == false ? 'question' : 'checkmark')}></i></button>
                        </div>
                        <input type="text" className="form-control" placeholder="Nombre del invitado" name={'boleto_adulto_' + x} value={adultos[x].nombre} onChange={e => onChangeInvitadoAdulto(e)}></input>
                        <div class="input-group-append">
                            <button class="btn btn-danger" type="button" id={'boleto_adulto_' + x} onClick={e => onDeleteAdulto(e)}><i className="icon-cancel-circle"></i></button>
                        </div>
                    </div>
                </div>
            );
        }

        return filas;
    }

    //Ninos
    const onChangeNinos = (e) => {
        let ninos = invitacionData.ninos;

        if(ninos?.length > e.target.value){
            ninos.splice(e.target.value, (ninos?.length - e.target.value));
        }else{
            for (var x = 1; x <= (e.target.value - ninos?.length); x++) {
                ninos.push({
                    nombre: '',
                    status: false
                });
            }
        }

        setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const onQuantityNinos = (tipo) => {
        let ninos = invitacionData.ninos;

        if(tipo == 1){
            ninos.push({
                nombre: '',
                status: false
            });
        }else{
            ninos.splice(ninos.length - 1, 1);
        }

        setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const onChangeInvitadoNino = (e) => {
        let ninos = invitacionData.ninos;

        let campo_separado = e.target.name.split('_');
        let posicion = campo_separado[2];

        ninos[posicion].nombre = e.target.value;

        setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const onConfirmNino = async ({ currentTarget }) => {
        let ninos = invitacionData.ninos;

        let campo_separado = currentTarget.id.split('_');
        let posicion = campo_separado[2];

        ninos[posicion].status = !ninos[posicion].status;

        setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const onDeleteNino = async ({ currentTarget }) => {
        let ninos = invitacionData.ninos;

        let campo_separado = currentTarget?.id?.split('_');
        let posicion = campo_separado[2];

        await ninos.splice(posicion, 1);

        await setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const ninosRows = (ninos) => {

        let filas = [];

        for (var x = 0; x < ninos?.length; x++) {
            filas.push(
                <div className="form-group">
                    <label className="font-weight-bold">Niño {x + 1}</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class={'btn btn_confirmar btn-' + (ninos[x].status == false ? 'light' : 'success')} type="button" id={'confirmacion_adulto_' + x} onClick={e => {onConfirmNino(e);}}><i className={'icon-' + (ninos[x].status == false ? 'question' : 'checkmark')}></i></button>
                        </div>
                        <input type="text" className="form-control" placeholder="Nombre del invitado" name={'boleto_nino_' + x} value={ninos[x].nombre} onChange={e => onChangeInvitadoNino(e) }></input>
                        <div class="input-group-append">
                            <button class="btn btn-danger" type="button" id={'boleto_nino_' + x} onClick={e => onDeleteNino(e)}><i className="icon-cancel-circle"></i></button>
                        </div>
                    </div>
                </div>
            );
        }

        return filas;
    }

    //Guardar
    const handleCancel = async (e) => {
        history.push('/invitaciones_listado');
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(editarInvitacion(invitacionData));
            await history.push('/invitaciones_listado');
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        dispatch(detalleInvitacion(id));
    }, [dispatch, id]);

    useEffect(() => {
        setInvitacionData({
            ...invitacionData,
            id: invitacion.detalle?._id,
            referencia: invitacion.detalle?.referencia,
            numero: invitacion.detalle?.numero,
            codigo: invitacion.detalle?.codigo,
            adultos: invitacion.detalle?.adultos,
            ninos: invitacion.detalle?.ninos,
        })
    }, [invitacion]);

    return(
        <div className="row px-3">
            <form className="col-md-8 offset-md-2">
                <div className="row mb-4 mt-5">
                    <div className="col-md-8">
                        <h2 style={styles.titulo_seccion}>Editar invitación</h2>
                    </div>
                    <div className="col-md-4 text-right">
                        <button type="submit" className="btn btn-danger mr-2" onClick={() => handleCancel()} style={styles.btn_tabla}>Cancelar</button>
                        <button type="submit" className="btn btn-success float-right" onClick={handleSubmit} style={styles.btn_tabla}>Editar invitación</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Referencia</label> <br></br>
                        <input type="text" className="form-control" placeholder="Nombre la familia o del invitado principal" name="referencia" onChange={e => onChange(e) } value={invitacionData.referencia || ''}></input>
                    </div>

                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Teléfono</label> <br></br>
                        <PhoneInput name="numero" className="form-control" country={'mx'} onChange={phone => setInvitacionData({...invitacionData, numero: phone})} value={invitacionData.numero || ''} />
                    </div>

                    <div className="col-md-3 form-group">
                        <label className="font-weight-bold">Código</label> <br></br>
                        <input type="text" className="form-control" name="codigo" onChange={e => onChange(e) } value={invitacionData.codigo || ''} disabled></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Boletos para adulto</label>
                        <div class="input-group">
                            <input type="number" min="0" className="form-control" placeholder="# de boletos" name="adultos" onChange={e => onChangeAdultos(e)} value={invitacionData.adultos?.length}></input>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityAdultos(1)}><i className="icon-plus"></i></button>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityAdultos(2)}><i className="icon-minus"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Boletos para niño</label>
                        <div class="input-group">
                            <input type="number" min="0" className="form-control" placeholder="# de boletos" name="ninos" onChange={e => onChangeNinos(e)} value={invitacionData.ninos?.length}></input>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityNinos(1)}><i className="icon-plus"></i></button>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityNinos(2)}><i className="icon-minus"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        { adultosRows(invitacionData.adultos) }
                    </div>

                    <div className="col-md-6">
                        { ninosRows(invitacionData.ninos) }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default InvitacionesEditar;