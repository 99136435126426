import { OBTENER_CODIGO } from '../actions/types'

const initialState = {
    codigo_qr: null
};

const fn = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case OBTENER_CODIGO:
            return {
                ...state,
                codigo_qr: payload
            }
        default:
            return state;
    }
}

export default fn;