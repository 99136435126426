import { combineReducers } from 'redux';
import auth from './auth'
import alert from './alert'
import dashboard from './dashboard'
import notas_blog from './notas_blog'
import invitaciones from './invitaciones'
import invitados from './invitados'
import mesas from './mesas'
import configuracion from './configuracion'
import loader from './loader'

export default combineReducers({
    auth, alert, dashboard, notas_blog, invitaciones, invitados, mesas, configuracion, loader
})