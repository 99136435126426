// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';

// Blog
export const OBTENER_DASHBOARD = 'OBTENER_DASHBOARD';

// Blog
export const BUSCAR_NOTAS = 'BUSCAR_NOTAS';
export const DETALLE_NOTA = 'DETALLE_NOTA';
export const ULTIMAS_NOTAS = 'ULTIMAS_NOTAS';
export const LISTAR_NOTAS = 'LISTAR_NOTAS';
export const CREAR_NOTA = 'CREAR_NOTA';
export const EDITAR_NOTA = 'EDITAR_NOTA';
export const ELIMINAR_NOTA = 'ELIMINAR_NOTA';

// Invitaciones
export const LISTAR_INVITACIONES = 'LISTAR_INVITACIONES';
export const CREAR_INVITACION = 'CREAR_INVITACION';
export const EDITAR_INVITACION = 'EDITAR_INVITACION';
export const ENVIAR_INVITACION = 'ENVIAR_INVITACION';
export const ELIMINAR_INVITACION = 'ELIMINAR_INVITACION';
export const DETALLE_INVITACION = 'DETALLE_INVITACION';

// Invitados
export const LISTAR_INVITADOS = 'LISTAR_INVITADOS';
export const CONFIRMAR_INVITADO = 'CONFIRMAR_INVITADO';
export const EDITAR_INVITADO = 'EDITAR_INVITADO';
export const ELIMINAR_INVITADO = 'ELIMINAR_INVITADO';

// Mesas
export const LISTAR_MESAS = 'LISTAR_MESAS';
export const CREAR_MESA = 'CREAR_MESA';
export const EDITAR_MESA = 'EDITAR_MESA';
export const DETALLE_MESA = 'DETALLE_MESA';
export const ELIMINAR_MESA = 'ELIMINAR_MESA';

// Configuración
export const OBTENER_CODIGO = 'OBTENER_CODIGO';

// Loader
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'