import { OBTENER_DASHBOARD } from '../actions/types'

const initialState = [];

const fn = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case OBTENER_DASHBOARD:
            return payload;
        default:
            return state;
    }
}

export default fn;