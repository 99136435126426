import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { listarMesas, detallesMesa, eliminarMesa } from '../../Redux/actions/mesas';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    contenido_tabla: {
    	fontSize: '16px'
    },
    btn_tabla: {
    	fontFamily: 'inherit',
    	letterSpacing: 'inherit',
    	fontSize: '16px'
    },
    imagen_fila: {
    	maxHeight: '90px'
    },
    badge_boton: {
        cursor: 'pointer'
    }
}

const MesasListado = () => {

	const dispatch = useDispatch();

	const { listado, detalles } = useSelector(state => state.mesas);

    const [filas, setFilas] = useState([]);
    const [filterText, setFilterText] = useState('');
	const [invitadosModal, setInvitadosModal] = useState(false);
	const [eliminacionModal, setEliminacionModal] = useState(false);

    const [invitadosData, setInvitadosData] = useState(null);

	const [eliminarData, setEliminarData] = useState({
        id: null
    });

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre', 
            selector: row => row.nombre,
        },
        {
            name: 'Limite de invitados', 
            selector: row => row.limite,
        },
        {
            name: 'Lugares disponibles', 
            selector: row => row.disponibles,
        },
        {
            name: 'Invitados asignados', 
            selector: row => row.ocupados,
        },
        {
            name: 'Adultos', 
            selector: row => row.numero_adultos,
        },
        {
            name: 'Niños', 
            selector: row => row.numero_ninos,
        }, 
        {
            name: '',
            grow: 1.5,
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link text-success px-2 float-end" onClick={() => handleAbrirInvitar(row)}><i className="icon-users"></i> Invitados</button>
                        <Link className="btn btn-sm btn-link text-primary px-2 float-end" to={`/mesas_listado/editar/${row._id}`}><i className="icon-pencil"></i></Link>
                        <button type="button" className="btn btn-sm btn-link text-danger px-2 float-end" onClick={() => handleAbrirEliminar(row)}><i className="icon-trash"></i></button>
                    </div>
                )
            }
        }
    ];

    //Filtrar
    const onChangeFilter = (e) => {
        setFilterText(e.target.value);
    }

    const handleClearFilter = () => {
        setFilterText('');
        setFilas(listado);
    }

    //Invitados
    const handleAbrirInvitar = item => {
        dispatch(detallesMesa(item._id));

        setInvitadosData(item._id);
    }

    const rowsInvitados = (items) => {
		if(items.length > 0){
        	return (
	        	<ol>
		            {
		            	items.map(item => (
			                <li key={item._id}>{item.nombre}</li>
		            	))
		        	}
	        	</ol>
        	);
        }else{
        	return (
                <p>No hay invitados de este tipo</p>
        	);
        }
    }

    const handleCerrarInvitar = () => {
        setInvitadosModal(false);

        setInvitadosData(null);
    }

    //Eliminar
    const handleAbrirEliminar = item => {
        setEliminacionModal(true);
        setEliminarData({
            ...eliminarData,
            id: item._id
        });
    }

    const handleEliminacion = async () => {
        await dispatch(eliminarMesa(eliminarData.id));
        await handleCerrarEliminar();
    }

    const handleCerrarEliminar = () => {
        setEliminacionModal(false);
        setEliminarData({
            id: null
        });
    }

	useEffect(() => {
        dispatch(listarMesas());
    }, []);

    useEffect(() => {
        if(detalles && invitadosData){
            setInvitadosModal(true);
        }
    }, [detalles, invitadosData]);

    useEffect(() => {
        setFilas(listado);
    }, [listado]);

    useEffect(() => {
        if(listado){
            setFilas(listado.filter(mesa => 
                (mesa.nombre && mesa.nombre.toLowerCase().includes(filterText.toLowerCase())) || 
                (
                    (filterText.toLowerCase() == "si") && mesa.disponibles > 0 ||
                    (filterText.toLowerCase() == "no") && mesa.disponibles <= 0
                )
            ));
        }
    }, [filterText]);

    return (
    	<Fragment>

            {/* Modal de invitados */}
            <Modal show={invitadosModal} size="lg" onHide={() => { handleCerrarInvitar() }}>
                <Modal.Header closeButton>
                    <Modal.Title>Mesa {detalles?.nombre || ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <p><b>Adultos</b></p>
                            { rowsInvitados(detalles?.adultos || []) }
                        </div>

                        <div className="col-md-6">
                            <p><b>Niños</b></p>
                            { rowsInvitados(detalles?.ninos || []) }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCerrarInvitar}>
                        De acuerdo
                    </Button>
                </Modal.Footer>
            </Modal>

    		{/* Modal de eliminación */}
            <Modal show={eliminacionModal} onHide={() => {
                setEliminacionModal(false);
                setEliminarData({
                    id: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar mesa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Deseas eliminar esta mesa?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleEliminacion}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

			<div className="container">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center heading-section mt-3">
						<h2 style={styles.titulo_seccion}>Administra tu lista de mesas</h2>
					</div>
					<div className="col-md-8 offset-md-2 text-center heading-section">
                        <Link to="/mesas_listado/nuevo" className="btn btn-primary text-right" style={styles.btn_tabla}>Nueva mesa</Link>
					</div>
				</div>
			</div>

            <div className="container_admin">
				{
                    listado?.length > 0 ? 
                        <Fragment>
                            <div className="col-md-12 form-group text-left">
                                <label className="font-weight-bold">Búsqueda general</label>
                                <div class="input-group">
                                    <input type="text" className="form-control" placeholder="Buscar por nombre o disponibilidad colocando 'si o no'" value={filterText} onChange={onChangeFilter}></input>
                                    <div class="input-group-append">
                                        <button class="btn btn-danger" type="button" onClick={handleClearFilter}><i className="icon-cancel-circle"></i></button>
                                    </div>
                                </div>
                            </div>

                            <DataTable 
                                columns={columns}
                                data={filas}
                                pagination
                                paginationPerPage={20}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                            />
                        </Fragment>
                    :
                        <div className="text-center">Aún no has configurado las mesas de tus 40 años 😱</div>
                }
			</div>
	    </Fragment>
    )
}

export default MesasListado;