import React, { useEffect } from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Inicio from './Views/Inicio'
import Detalles from './Views/Detalles'
import Invitados from './Views/Invitados'
import Regalos from './Views/Regalos'
import Galeria from './Views/Galeria'
import Blog from './Views/Blog'
import BlogDetalle from './Views/BlogDetalle'
import Login from './Views/Login'
import Dashboard from './Views/Dashboard'
import InvitacionesListado from './Views/InvitacionesListado'
import InvitacionesNuevo from './Views/InvitacionesNuevo'
import InvitacionesEditar from './Views/InvitacionesEditar'
import InvitadosListado from './Views/InvitadosListado'
import MesasListado from './Views/MesasListado'
import MesasNuevo from './Views/MesasNuevo'
import MesasEditar from './Views/MesasEditar'
import BlogListado from './Views/BlogListado'
import BlogNuevo from './Views/BlogNuevo'
import BlogEditar from './Views/BlogEditar'
import Configuracion from './Views/Configuracion'

import Cobertura from './Components/Layout/Cobertura'
import Image from './Components/Layout/Image'
import Header from './Components/Layout/Header'
import HeaderSesion from './Components/Layout/HeaderSesion'
import Alert from './Components/Layout/Alert'
import Footer from './Components/Layout/Footer'
import Loader from './Components/Layout/Loader'

import setAuthToken from './Utils/setAuthToken'
import PrivateRoute from './Components/Routing/PrivateRoute'

import { Provider } from 'react-redux';
import store from './store'
import { loadUser } from './Redux/actions/auth'

function App({ history }) {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    useEffect(() => {
        store.dispatch(loadUser());
    }, [])

    return (<Provider store={store}>
        <Router>
            <Switch>
                <Route exact path="/">
                    <div id="fh5co-wrapper">
                        <div id="fh5co-page">
                            <Cobertura />
                            <Alert></Alert>
                            <Image />
                            <Header />
                            <Inicio />
                            <Footer />
                        </div>
                    </div>
                </Route>
                <PrivateRoute exact path="/dashboard">
                    <Loader></Loader>
                    <Alert></Alert>
                    <HeaderSesion />
                    <Dashboard />
                </PrivateRoute>
                <PrivateRoute exact path="/invitaciones_listado">
                    <Loader></Loader>
                    <Alert></Alert>
                    <HeaderSesion />
                    <InvitacionesListado />
                </PrivateRoute>
                <PrivateRoute exact path="/invitaciones_listado/nuevo">
                    <Alert></Alert>
                    <HeaderSesion />
                    <InvitacionesNuevo />
                </PrivateRoute>
                <PrivateRoute exact path="/invitaciones_listado/editar/:id">
                    <Alert></Alert>
                    <HeaderSesion />
                    <InvitacionesEditar />
                </PrivateRoute>
                <PrivateRoute exact path="/invitados_listado">
                    <Loader></Loader>
                    <Alert></Alert>
                    <HeaderSesion />
                    <InvitadosListado />
                </PrivateRoute>
                <PrivateRoute exact path="/mesas_listado">
                    <Loader></Loader>
                    <Alert></Alert>
                    <HeaderSesion />
                    <MesasListado />
                </PrivateRoute>
                <PrivateRoute exact path="/mesas_listado/nuevo">
                    <Alert></Alert>
                    <HeaderSesion />
                    <MesasNuevo />
                </PrivateRoute>
                <PrivateRoute exact path="/mesas_listado/editar/:id">
                    <Alert></Alert>
                    <HeaderSesion />
                    <MesasEditar />
                </PrivateRoute>
                <PrivateRoute exact path="/configuracion">
                    <Alert></Alert>
                    <HeaderSesion />
                    <Configuracion />
                </PrivateRoute>
                <Route exact path="/login">
                    <Alert></Alert>
                    <div id="main-wrap">
                        <div id="page-content" className="header-static">
                            <Login history={history} />
                        </div>
                    </div>
                </Route>
                <Route path="/">
                    <div id="fh5co-wrapper">
                        <div id="fh5co-page">
                            <Alert></Alert>
                            <Image />
                            <Header />
                            <Inicio />
                            <Footer />
                        </div>
                    </div>
                </Route>
            </Switch>
        </Router>
    </Provider >);
}

export default App;
