import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { listarNotas, eliminarBlog } from '../../Redux/actions/notas_blog';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    contenido_tabla: {
    	fontSize: '16px'
    },
    btn_tabla: {
    	fontFamily: 'inherit',
    	letterSpacing: 'inherit',
    	fontSize: '16px'
    },
    imagen_fila: {
    	maxHeight: '90px'
    }
}

const BlogListado = () => {

	const dispatch = useDispatch();

	const { listado } = useSelector(state => state.notas_blog);

    const [filas, setFilas] = useState([]);
    const [filterText, setFilterText] = useState('');
	const [eliminacionModal, setEliminacionModal] = useState(false);

	const [eliminarData, setEliminarData ] = useState({
        id: null
    });

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: '', 
            cell: row => {
                return(
                    <img className="img-fluid" src={row.imagen_mini != null ? row.imagen_mini : '/assets/images/default_mini.png'} style={styles.imagen_fila} alt="" />
                )
            }
        },
        {
            name: 'Título', 
            selector: row => row.titulo,
        },
        {
            name: 'Fecha', 
            selector: row => new Date(row.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }),
        },
        {
            name: 'Contenido', 
            selector: row => strip_html_tags(row.contenido),
        },
        {
            name: '',
            grow: 1.5,
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <Link className="btn btn-sm btn-link text-primary px-2 float-end" to={`/blog_listado/editar/${row._id}`}><i className="icon-pencil"></i></Link>
                        <button type="button" className="btn btn-sm btn-link text-danger px-2 float-end" onClick={() => handleEliminar(row)}><i className="icon-trash"></i></button>
                    </div>
                )
            }
        }
    ];

    //Filtrar
    const onChangeFilter = (e) => {
        setFilterText(e.target.value);
    }

    const handleClearFilter = () => {
        setFilterText('');
        setFilas(listado);
    }

    const handleEliminar = item => {
        setEliminacionModal(true);
        setEliminarData({
            ...eliminarData,
            id: item._id
        });
    }

    const handleEliminacion = () => {
        dispatch(eliminarBlog(eliminarData.id));
        setEliminacionModal(false);
        setEliminarData({
            id: null
        });
    }

    const strip_html_tags = (str) => {
		if ((str===null) || (str===''))
			return '';
		else
			str = str.toString();
		return str.replace(/<[^>]*>/g, '').substr(0, 80);
	}

	useEffect(() => {
        dispatch(listarNotas());
    }, []);

    useEffect(() => {
        setFilas(listado);
    }, [listado]);

    useEffect(() => {
        if(listado){
            setFilas(listado.filter(mesa => 
                (mesa.titulo && mesa.titulo.toLowerCase().includes(filterText.toLowerCase())) || 
                (mesa.fecha && new Date(mesa.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }).toLowerCase().includes(filterText.toLowerCase())) || 
                (mesa.contenido && mesa.contenido.toLowerCase().includes(filterText.toLowerCase()))
            ));
        }
    }, [filterText]);
    
    return (
    	<Fragment>

    		{/* Modal de eliminación */}
            <Modal show={eliminacionModal} onHide={() => {
                setEliminacionModal(false);
                setEliminarData({
                    id: null
                });
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Deseas eliminar este post?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleEliminacion}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

			<div className="container">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center heading-section mt-3">
						<h2 style={styles.titulo_seccion}>Agrega, edita o elimina tus post</h2>
					</div>
					<div className="col-md-8 offset-md-2 text-center heading-section">
						<Link to="/blog_listado/nuevo" className="btn btn-primary text-right" style={styles.btn_tabla}>Nuevo post</Link>
					</div>
				</div>
			</div>

			<div className="container_admin">
				{
                    listado?.length > 0 ? 
                        <Fragment>
                            <div className="col-md-12 form-group text-left">
                                <label className="font-weight-bold">Búsqueda general</label>
                                <div class="input-group">
                                    <input type="text" className="form-control" placeholder="Buscar por título, fecha o contenido" value={filterText} onChange={onChangeFilter}></input>
                                    <div class="input-group-append">
                                        <button class="btn btn-danger" type="button" onClick={handleClearFilter}><i className="icon-cancel-circle"></i></button>
                                    </div>
                                </div>
                            </div>

                            <DataTable 
                                columns={columns}
                                data={filas}
                                pagination
                                paginationPerPage={20}
                                paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                            />
                        </Fragment>
                    :
                        <div className="text-center">Aún no has agregado momentos para compartir 😱</div>
                }
			</div>
	    </Fragment>
    )
}

export default BlogListado;