import axios from 'axios';
import { OBTENER_DASHBOARD } from './types';
import { setAlert } from './alert';
import { changeLoader } from './loader';

// LISTADO INVITACIONES
export const getDashboard = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ 'id_compra': process.env.REACT_APP_ID_COMPRA });

    try {
        dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/dashboard_boda/`, body, config);
        dispatch(changeLoader(false));

        dispatch({
            type: OBTENER_DASHBOARD,
            payload: res.data.dashboard
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
    }
}