import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAILED, USER_LOADED, AUTH_ERROR, LOGOUT } from './types';
import setAuthToken from '../../Utils/setAuthToken';
import { setAlert } from './alert';


// LOAD USER
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        let res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/cliente_auth`);

        dispatch({
            type: USER_LOADED,
            payload: {
                user: res.data.usuario
            }
        });
    } catch (error) {
        dispatch({
            type: AUTH_ERROR
        })
    }

}

// LOGIN USER
export const login = ({ usuario, password }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ usuario, password });

    try {

        let url = `${process.env.REACT_APP_API_ROUTE}/cliente_auth`;
        const res = await axios.post(url, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                token: res.data.token,
                user: res.data.usuario
            }
        });
    } catch (error) {
        if(error.response){
            if (error.response.data.errors) {
                error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }else{
                dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
            }
        }else{
            dispatch(setAlert('Conexión fallida con el servidor', 'danger'));
        }
        dispatch({
            type: LOGIN_FAILED
        })
    }
}

// Logout
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}