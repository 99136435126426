import { SHOW_LOADER, HIDE_LOADER } from '../actions/types'

const initialState = false;

const fn = (state = initialState, action) => {

    const { type } = action;

    switch (type) {
        case SHOW_LOADER:
            return true;
        case HIDE_LOADER:
            return false;
        default:
            return state;
    }
}

export default fn;