import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";

import { obtenerCodigo, reconectarWhatsapp } from '../../Redux/actions/configuracion';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    }
}

const Configuracion = () => {

	const dispatch = useDispatch();

	const configuracion = useSelector(state => state.configuracion);

	const handleRegenerar = async () => {
		dispatch(obtenerCodigo());
    }

    const handleReconectar = async () => {
		dispatch(reconectarWhatsapp());
    }
	
	useEffect(() => {
        if(configuracion.codigo_qr != null){
        	ReactDOM.render(<QRCode value={configuracion.codigo_qr} />, document.getElementById('qr_container'));
        }
    }, [configuracion]);

	useEffect(() => {
        dispatch(obtenerCodigo());
    }, [dispatch]);
    
    return (
    	<Fragment>
			<div className="container">
				<div className="row">
					<div className="col-md-8 offset-md-2 text-center heading-section mt-3">
						<h2 style={styles.titulo_seccion}>Conexión con WhatsApp</h2>
					</div>
					<div className="col-md-8 offset-md-2 text-center heading-section">
						<button type="button" className="btn btn-primary mx-2" onClick={() => handleRegenerar()}>Regenerar código</button>
						<button type="button" className="btn btn-warning mx-2" onClick={() => handleReconectar()}>Reconectar</button>
					</div>
				</div>
			</div>
			<div className="container text-center">
				<div id="qr_container"></div>
			</div>
	    </Fragment>
    )
}

export default Configuracion;